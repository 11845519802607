/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit';
import TransportationServices from 'services/transportation/transportation.service';

export const fetchTransportationOrders = createAsyncThunk(
	'fetchTransportationOrders',
	async ({ searchParams, loggedUser }, thunkAPI) => {
		const { rejectWithValue } = thunkAPI;

		try {
			const { data } = await TransportationServices.instance.fetchTransportationJobs(searchParams, loggedUser);

			if (data?.success) {
				const response = data.data;

				const baseResponse = { entities: {}, orderIds: [] };
				const parsedResponse = response.reduce(
					({ entities, orderIds }, tj) => ({
						entities: { ...entities, [tj.id]: tj },
						orderIds: [...orderIds, tj.id],
					}),
					baseResponse,
				);

				return { ...parsedResponse, metadata: { ...data.metadata, page_items_count: response.length } };
			}

			return rejectWithValue({ errorMessage: 'Transportation Orders could not be fetched' });
		} catch (response) {
			const message = response?.error?.message || 'Transportation Orders could not be fetched';
			return rejectWithValue({ errorMessage: message });
		}
	},
);

export const removeTransportOrder = createAsyncThunk(
	'removeTransportOrder',
	async ({ orderId, loggedUser }, thunkAPI) => {
		const { rejectWithValue } = thunkAPI;

		try {
			const { data } = await TransportationServices.instance.removeTransport(orderId, loggedUser);

			if (data?.success) {
				return { orderId };
			}

			return rejectWithValue({ errorMessage: 'Transportation could not be canceled' });
		} catch (response) {
			const message = response?.error?.message || 'Transportation could not be canceled';
			return rejectWithValue({ errorMessage: message });
		}
	},
);
