import { MARKETPLACE_TAB } from 'constants/marketplace';
import { LIST_KEYS } from 'store/slices/buyerVehicles/helpers/vehicles';

const featuredVehiclesInitialState = {
	isLoading: false,
	errorMessage: '',
	showFeaturedVehicles: false,
	id: '',
	searchFilter: {},
	seeMoreText: '',
	title: '',
	vehicleIds: [],
};

export const initSearchList = (searchListKey) => ({
	[searchListKey]: {
		currentSearch: {
			params: {
				page: 1,
			},
		},
		totalElements: 0,
		isReady: false, // will be true once the first load is ended
		isLoading: false,
		makes: [],
		pageCount: 0,
		errorMessage: '',
		vehicleIds: [],
		itemsPerPage: 30,
		// Featured
		featured: { ...featuredVehiclesInitialState },
	},
});

export default {
	searchLists: {
		...initSearchList(MARKETPLACE_TAB.marketplace),
		...initSearchList(MARKETPLACE_TAB.offers),
		...initSearchList(MARKETPLACE_TAB.watchlist),
		...initSearchList(MARKETPLACE_TAB.hidden),
		...initSearchList(LIST_KEYS.auction),
		...initSearchList(LIST_KEYS.offers),
		...initSearchList(LIST_KEYS.hidden),
		...initSearchList(LIST_KEYS.watchlist),
	},
};
