import { combineReducers } from '@reduxjs/toolkit';
import buyerTimedSaleReducer from './slices/auctionStatus/slice';
import buyerTimedSaleVehiclesReducer from './slices/buyerVehicles/buyerVehicles';
import sellerBidSalesReducer from './slices/sellers/bidSales/slice';
import vehicleCardModalsReducer from './slices/sellers/vehicleCardModals/vehicleCardModalsSlice';
import observedBidSalesReducer from './slices/observedBidSales/observedBidSalesSlice';
import buyerOfferSummary from './slices/offerSummary/buyerOfferSummary';
import atomicTimeReducer from './slices/atomicTime/atomicTimeSlice';
import auctionPoolingReducer from './slices/auctionPoolingUpdater/auctionPoolingSlice';
import configurableFiltersReducer from './slices/buyers/filters/filtersSlice';
import loggedUserReducer from './slices/loggedUsers/loggedUserSlice';
import { reducer as vehicleDetailPageReducer } from './slices/vehicleDetailPage';
import checkoutReducer from './slices/checkout';
import referAFriendReducer from './slices/referAFriend/referAFriendSlice';
import gtvOfferShowReducer from './slices/gtvOffer/gtvOfferShow';
import gtvOfferIndexReducer from './slices/gtvOffer/gtvOfferIndex';
import savedSearchReducer from './slices/savedSearch/savedSearchSlice';
import transportDashboardReducer from './slices/transportDashboard/slice';

export default combineReducers({
	vehicleCardModals: vehicleCardModalsReducer,
	sellerBidSales: sellerBidSalesReducer,
	buyerTimedSale: buyerTimedSaleReducer,
	buyerVehicles: buyerTimedSaleVehiclesReducer,
	observedBidSales: observedBidSalesReducer,
	buyerOfferSummary: buyerOfferSummary,
	atomicTime: atomicTimeReducer,
	auctionPooling: auctionPoolingReducer,
	searchFilters: configurableFiltersReducer,
	loggedUser: loggedUserReducer,
	vehicleDetailPage: vehicleDetailPageReducer,
	checkout: checkoutReducer,
	referAFriend: referAFriendReducer,
	gtvOfferShow: gtvOfferShowReducer,
	gtvOfferIndex: gtvOfferIndexReducer,
	savedSearch: savedSearchReducer,
	transportDashboard: transportDashboardReducer,
});
