import { authenticatedRestClient } from 'helpers/restclient';
import { getAuthHeaders } from 'helpers/restclient/auth';

export default class TransportationServices {
	static instance = new TransportationServices();

	#endpoints = {
		jobs: '/api/transportation/jobs',
	};

	constructor() {
		this.restClient = authenticatedRestClient;
	}

	fetchTransportationJobs = (payload, loggedUser) => {
		const endpoint = this.#endpoints.jobs;
		return this.restClient.get(endpoint, payload, getAuthHeaders(loggedUser));
	};

	removeTransport = (orderId, loggedUser) => {
		const endpoint = `${this.#endpoints.jobs}/${orderId}`;
		return this.restClient.delete(endpoint, getAuthHeaders(loggedUser));
	};
}
