/* eslint-disable camelcase */
import dayjs from 'dayjs';
import { useVehicleCardContext } from '../Context/vehicle-card.context';

export const getTags = ({ badges }) => badges?.map(({ key }) => key);

export const isMarketplace = ({ sale_type }) => ['marketplace', 'private_marketplace_sale'].includes(sale_type);

export const hasStarted = ({ bid_sale_auction_starts_at }, overrideDateNow) =>
	dayjs(bid_sale_auction_starts_at).isBefore(dayjs(overrideDateNow));

export const hasEnded = ({ bid_sale_auction_ends_at }, overrideDateNow) =>
	dayjs(bid_sale_auction_ends_at).isBefore(dayjs(overrideDateNow));

export const isOnExtension = ({ bid_sale_auction_extended_until }, overrideDateNow) =>
	dayjs(bid_sale_auction_extended_until).isAfter(dayjs(overrideDateNow));

export const didUserAlreadyBid = ({ my_offer_amount }) => Boolean(my_offer_amount);

export const getCounterBuyerText = (
	{
		best_offer_dealership_name,
		best_offer_date,
		bid_sale_auction_extended_until,
		bid_sale_auction_ends_at,
		best_offer_firm,
	},
	masterOnBehalfOf,
) => {
	const buyerHasCounterOffer = dayjs(best_offer_date).isAfter(
		bid_sale_auction_extended_until || bid_sale_auction_ends_at,
	);

	if (masterOnBehalfOf) {
		return best_offer_firm ? `${best_offer_dealership_name} (firm)` : best_offer_dealership_name;
	} else {
		if (best_offer_firm) {
			return 'You (firm)';
		}
		return buyerHasCounterOffer ? 'You countered' : 'Your high bid';
	}
};

export const getCounterSellerText = ({ lowest_counter_amount, best_offer_firm }) => {
	const sellerCounterOffer = !!lowest_counter_amount;
	if (sellerCounterOffer) {
		return best_offer_firm ? 'Seller (firm)' : 'Seller countered';
	} else {
		return 'Waiting for seller';
	}
};

export const buyerHasMaxBid = ({ my_offer_max_bid_amount }) => !!my_offer_max_bid_amount;

export const buyerHasOffer = ({ my_offer_amount }) => !!my_offer_amount;

export const sellerIsLastCounter = ({ lowest_counter_date, best_offer_date }) =>
	dayjs(lowest_counter_date).isAfter(best_offer_date);

export const buyerHasCounterOffer = ({ bid_sale_auction_extended_until, bid_sale_auction_ends_at, best_offer_date }) =>
	dayjs(best_offer_date).isAfter(bid_sale_auction_extended_until || bid_sale_auction_ends_at);

export const sellerHasCounterOffer = ({ lowest_counter_date }) => !!lowest_counter_date;

export const useIsExclusivityPeriodOnOtherUser = () => {
	const { overrideDateNow, vehicle, currentUserTopOfferer } = useVehicleCardContext();
	const { exclusivity_period, exclusivity_period_expires_at } = vehicle;
	const exclusivityPeriodOnOtherUser =
		!currentUserTopOfferer &&
		exclusivity_period &&
		dayjs(overrideDateNow).isBefore(dayjs(exclusivity_period_expires_at));

	return exclusivityPeriodOnOtherUser;
};
