import { TRANSPORT_DASHBOARD_SORTING_OPTIONS, TRANSPORT_DASHBOARD_TAB } from 'constants/transport_dashboard';
import { createSlice } from '@reduxjs/toolkit';
import { fetchTransportationOrders, removeTransportOrder } from './thunks';
import { getSanitizedFilterParams } from '../buyers/filters/helpers';

const initialSearchListFilters = {
	currentSearch: {
		params: {
			keywords: '',
			page: 1,
			order_by: TRANSPORT_DASHBOARD_SORTING_OPTIONS[0].order_by,
			direction: TRANSPORT_DASHBOARD_SORTING_OPTIONS[0].direction,
		},
	},
	totalElements: 0,
	pageCount: 0,
	pageItemsCount: 0,
	isLoading: false,
	errorMessage: '',
	itemsPerPage: 10,
	sortingOptions: TRANSPORT_DASHBOARD_SORTING_OPTIONS,
	orderIds: [],
};

export const initialState = {
	focussedTab: TRANSPORT_DASHBOARD_TAB.pending,
	searchLists: {
		[TRANSPORT_DASHBOARD_TAB.pending]: initialSearchListFilters,
		[TRANSPORT_DASHBOARD_TAB.delivered]: initialSearchListFilters,
	},
	defaultFilterParams: {
		keywords: '',
		page: 1,
		order_by: TRANSPORT_DASHBOARD_SORTING_OPTIONS[0].order_by,
		direction: TRANSPORT_DASHBOARD_SORTING_OPTIONS[0].direction,
		status: TRANSPORT_DASHBOARD_TAB.pending,
	},
	entities: {},
	removeTransportModal: {
		isLoading: false,
		selectedOrderId: null,
		isOpen: false,
		showError: false,
		removedSuccess: false,
	},
};

export const transportDashboard = createSlice({
	name: 'transportDashboard',
	initialState,
	reducers: {
		setKeywords: (state, { payload }) => {
			const { searchListKey: listKey, keywords } = payload;
			const currentSearch = state.searchLists[listKey].currentSearch;
			currentSearch.params.keywords = keywords;
		},
		changeSorting: (state, { payload }) => {
			const { searchListKey: listKey, order_by: orderBy, direction } = payload;
			const currentSearch = state.searchLists[listKey].currentSearch;
			const oldValues = {
				orderBy: currentSearch.params.order_by,
				direction: currentSearch.params.direction,
			};

			if (oldValues.orderBy !== orderBy || oldValues.direction !== direction) {
				currentSearch.params.order_by = orderBy;
				currentSearch.params.direction = direction;
				currentSearch.params.page = 1;
			}
		},
		pageChange: (state, { payload }) => {
			const { listKey, page } = payload;
			const currentSearch = state.searchLists[listKey].currentSearch;
			currentSearch.params.page = page;
		},
		setFocussedTab: (state, { payload }) => {
			const { focussedTab } = payload;
			state.focussedTab = focussedTab;
		},
		updateCurrentSearchFromQueryParam: (state, { payload }) => {
			const { listKey, searchParams } = payload;

			state.focussedTab = listKey;
			const currentSearch = state.searchLists[listKey].currentSearch;
			// eslint-disable-next-line no-unused-vars
			const { status, ...searchListDefaultParams } = state.defaultFilterParams;

			currentSearch.params = getSanitizedFilterParams(searchParams, searchListDefaultParams, []);
		},
		setSelectedOrderId: (state, { payload }) => {
			const { orderId } = payload;

			state.removeTransportModal.selectedOrderId = orderId;
			state.removeTransportModal.isOpen = true;
		},
		closeTransportModal: (state) => {
			state.removeTransportModal.isOpen = false;
			state.removeTransportModal.isLoading = false;
			state.removeTransportModal.removedSuccess = false;
			state.removeTransportModal.showError = false;
			state.removeTransportModal.selectedOrderId = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchTransportationOrders.pending, (state) => {
			const focussedTab = state.searchLists[state.focussedTab];

			focussedTab.isLoading = true;
			focussedTab.errorMessage = '';
			focussedTab.orderIds = [];
		});
		builder.addCase(fetchTransportationOrders.fulfilled, (state, { payload: { entities, orderIds, metadata } }) => {
			const focussedTab = state.searchLists[state.focussedTab];
			const currentSearch = focussedTab.currentSearch;

			focussedTab.isLoading = false;
			focussedTab.totalElements = metadata.total_count;
			focussedTab.pageCount = metadata.page_count;
			focussedTab.pageItemsCount = metadata.page_items_count;
			focussedTab.orderIds = orderIds;
			currentSearch.params.page = metadata.page;
			state.entities = { ...state.entities, ...entities };
		});
		builder.addCase(fetchTransportationOrders.rejected, (state, { meta, payload }) => {
			if (!meta.aborted) {
				const focussedTab = state.searchLists[state.focussedTab];

				focussedTab.isLoading = false;
				focussedTab.errorMessage = payload?.errorMessage;
				focussedTab.totalElements = 0;
				focussedTab.pageCount = 0;
				focussedTab.pageItemsCount = 0;
			}
		});
		builder.addCase(removeTransportOrder.pending, (state) => {
			state.removeTransportModal.isLoading = true;
			state.removeTransportModal.showError = false;
		});
		builder.addCase(removeTransportOrder.fulfilled, (state, { payload: { orderId } }) => {
			const focussedTab = state.searchLists[state.focussedTab];
			focussedTab.pageItemsCount--;
			focussedTab.totalElements--;
			focussedTab.orderIds = focussedTab.orderIds.filter((value) => value != orderId);

			// eslint-disable-next-line no-unused-vars
			const { [orderId]: orderToRemove, ...updatedEntities } = state.entities;

			state.removeTransportModal.isLoading = false;
			state.removeTransportModal.removedSuccess = true;

			state.entities = updatedEntities;
		});
		builder.addCase(removeTransportOrder.rejected, (state, { meta }) => {
			if (!meta.aborted) {
				state.removeTransportModal.isLoading = false;
				state.removeTransportModal.showError = true;
			}
		});
	},
});

export const {
	changeSorting,
	pageChange,
	setFocussedTab,
	setKeywords,
	updateCurrentSearchFromQueryParam,
	setSelectedOrderId,
	closeTransportModal,
} = transportDashboard.actions;

export default transportDashboard.reducer;
