/* eslint-disable camelcase */
import { buildParamsURL } from 'helpers/search';
import { useSelector } from 'react-redux';

export const getAreFiltersInitialized = (state, searchListKey) =>
	state.searchFilters.searchLists[searchListKey].areFiltersInitialized;

export const useAreFiltersInitialized = (searchListKey) =>
	useSelector((state) => getAreFiltersInitialized(state, searchListKey));

const getAreFiltersLoading = (state, searchListKey) => state.searchFilters.searchLists[searchListKey].areFiltersLoading;
export const useAreFiltersLoading = (searchListKey) =>
	useSelector((state) => getAreFiltersLoading(state, searchListKey));

export const useThereAreFilters = (searchListKey) =>
	useSelector((state) => Boolean(state.searchFilters.searchLists[searchListKey].filterSections.length));

export const useIsFilterModalOpened = (searchListKey) =>
	useSelector((state) => state.searchFilters.searchLists[searchListKey].isFilterModalOpened);

export const getDraftFilterState = (state, searchListKey) =>
	state.searchFilters.searchLists[searchListKey].draft.paramsFilters;
export const useDraftFilterState = (searchListKey) => useSelector((state) => getDraftFilterState(state, searchListKey));

export const useIsDraftPreviewInProgress = (searchListKey) =>
	useSelector((state) => state.searchFilters.searchLists[searchListKey].draft.isPreviewLoading);

export const useDraftPreviewVehiclesMatches = (searchListKey) =>
	useSelector((state) => state.searchFilters.searchLists[searchListKey].draft.vehiclesThatMatchFilters);

export const useDraftChangedFilters = (searchListKey) =>
	useSelector((state) => state.searchFilters.searchLists[searchListKey].draft.changedFilters);

export const getFiltersSections = (state, searchListKey) =>
	state.searchFilters.searchLists[searchListKey].filterSections;
export const useFiltersSections = (searchListKey) => useSelector((state) => getFiltersSections(state, searchListKey));

const getHotfilters = (state, searchListKey) => state.searchFilters.searchLists[searchListKey].hotfilters;
export const useHotfilters = (searchListKey) => useSelector((state) => getHotfilters(state, searchListKey));

export const useConfigurableTabs = () => useSelector((state) => state.searchFilters.tabs);

export const getFiltersParams = (state, searchListKey) => ({
	...state.searchFilters.searchLists[searchListKey].currentSearch,
	params: {
		...state.searchFilters.searchLists[searchListKey].currentSearch.params,
		...state.searchFilters.configs,
	},
});
export const useFilterParams = (searchListKey) => useSelector((state) => getFiltersParams(state, searchListKey).params);
export const useFilterParamsVersion = (searchListKey) =>
	useSelector((state) => getFiltersParams(state, searchListKey).paramsVersion);
export const getAppliedFilters = (state, searchListKey) =>
	state.searchFilters.searchLists[searchListKey].appliedFilters;
export const useAppliedFilters = (searchListKey) => useSelector((state) => getAppliedFilters(state, searchListKey));

export const useSearchQueryParams = (searchListKey) =>
	useSelector(
		(state) =>
			`${buildParamsURL(
				{
					...getFiltersParams(state, searchListKey).params,
					// focused tab is stored on buyerVehicles slice.
					...(state.buyerVehicles.focussedTab ? { tab: state.buyerVehicles.focussedTab } : {}),
				},
				getFiltersParams(state, searchListKey).defaultFilterParams,
				false,
			)}`,
	);

export const useAreInitialListConfigLoaded = () => useSelector((state) => state.searchFilters.areConfigsLoaded);

export const useFilterSection = (searchListKey, sectionName) =>
	useSelector((state) => {
		const sections = state.searchFilters.searchLists[searchListKey]?.filterSections;
		return sections?.find((section) => section.key === sectionName);
	});

export const useAppliedFilter = (searchListKey, filterKey) =>
	useSelector((state) =>
		state.searchFilters.searchLists[searchListKey].appliedFilters?.filters?.find(
			(filter) => filter.key === filterKey,
		),
	);

export const useIsOrderByExtensionEnabled = (focussedTab) =>
	useSelector((state) => state.searchFilters?.searchLists?.[focussedTab]?.currentSearch?.params?.order_by_extension);

export const useSortingOptions = (focussedTab) =>
	useSelector((state) => state.searchFilters?.searchLists?.[focussedTab]?.sortingOptions);

export const useSelectedCheckboxes = (parentValue, param, params) => {
	let checkboxes = params?.[param];
	checkboxes = typeof checkboxes === 'string' ? checkboxes?.split(',') : checkboxes;
	return (
		checkboxes?.filter((item) =>
			parentValue ?
				item.toLowerCase().startsWith(`${parentValue.toLowerCase()}`) &&
				item.toLowerCase() !== parentValue.toLowerCase()
			:	true,
		).length || 0
	);
};

const getExpandedOptions = (filter) =>
	filter.options.filter(({ is_expanded }) => is_expanded).map(({ value }) => value);

export const useExpandedFilterOptions = (searchListKey, sectionKey, filterKey) =>
	useSelector((state) => {
		try {
			const section = state.searchFilters.searchLists[searchListKey]?.filterSections?.find(
				({ key }) => key === sectionKey,
			);
			const filter = section.filters.find(({ key }) => key === filterKey);
			const expandedOptions = getExpandedOptions(filter);
			return expandedOptions;
		} catch (error) {
			return [];
		}
	});

export const useExpandedFilterOptionsHotfilters = (searchListKey, sectionKey, filterKey) =>
	useSelector((state) => {
		try {
			const filter = state.searchFilters.searchLists[searchListKey]?.hotfilters?.find(
				({ key }) => key === filterKey,
			);
			const expandedOptions = getExpandedOptions(filter);
			return expandedOptions;
		} catch (error) {
			return [];
		}
	});
