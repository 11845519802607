export const TRANSPORT_DASHBOARD_TAB = {
	pending: 'pending',
	delivered: 'delivered',
};

export const TRANSPORT_DASHBOARD_TABS_ORDER = [TRANSPORT_DASHBOARD_TAB.pending, TRANSPORT_DASHBOARD_TAB.delivered];

export const TRANSPORT_DASHBOARD_SORTING_OPTIONS = [
	{
		key: 'newest_first',
		position: 1,
		order_by: 'created_at',
		direction: 'desc',
	},
	{
		key: 'oldest_first',
		position: 2,
		order_by: 'created_at',
		direction: 'asc',
	},
	{
		key: 'delivered_newest_first',
		position: 3,
		order_by: 'delivered_at',
		direction: 'desc',
	},
	{
		key: 'delivered_oldest_first',
		position: 4,
		order_by: 'delivered_at',
		direction: 'asc',
	},
];
