import { createSlice } from '@reduxjs/toolkit';
import { isOlderVersion } from 'helpers/timedSales/versionChecker';
import { calculateSummaryFromList, isCurrentUserTheTopOfferer } from 'store/slices/offerSummary/helper';

const initialState = {
	bidSalesThatUserHasOfferer: [],
	dealershipsId: [], // logged user dealership_id, or subordinates id,
	loading: false,
	success: null,
	stats: {
		leaderCount: 0,
		outbiddedCount: 0,
		wonCount: 0,
		lostCount: 0,
		counterCount: 0,
	},
};

const removeUnusedFields = (offerData) => ({
	bid_sale_id: offerData.bid_sale_id,
	top_offerer_dealership_id: offerData.best_offer_offerer_dealership_id || offerData.top_offerer_dealership_id,
	auction_status: offerData.auction_status || offerData.bid_sale_auction_status,
	status: offerData.status,
	lowest_counter_date: offerData.lowest_counter_date,
	best_offer_date: offerData.best_offer_date,
	version: offerData.version,
});

export const buyerOfferSummarySlice = createSlice({
	name: 'buyerOfferSummary',
	initialState,
	reducers: {
		updateOfferSummary: (state, { payload }) => {
			const { fromUserBid } = payload;

			const incomingChanges = removeUnusedFields(payload);

			const { dealershipsId } = state;

			const isUserTheTopOfferer = isCurrentUserTheTopOfferer({
				topOffererDealershipId: incomingChanges.top_offerer_dealership_id,
				dealershipsId,
			});

			const foundIndex = state.bidSalesThatUserHasOfferer.findIndex(
				(bidSale) => bidSale.bid_sale_id === incomingChanges.bid_sale_id,
			);
			const wasElementFound = foundIndex > -1;

			if (wasElementFound) {
				const elementToUpdate = state.bidSalesThatUserHasOfferer[foundIndex];
				if (
					!elementToUpdate.version ||
					!isOlderVersion({
						incomingChanges: { version: incomingChanges.version },
						storedState: elementToUpdate,
					})
				) {
					state.bidSalesThatUserHasOfferer[foundIndex] = {
						...elementToUpdate,
						...incomingChanges,
					};
				}
			}

			// We force push if fromUserBid is true because if user makes a bid for a vehicle with higher autobid
			// WS sends the message of autobid increase and not the current user bid stats will not include that current user was outbidded
			if (!wasElementFound && (isUserTheTopOfferer || fromUserBid)) {
				state.bidSalesThatUserHasOfferer.push(incomingChanges);
			}

			state.stats = calculateSummaryFromList({
				bidSalesThatUserHasOfferer: state.bidSalesThatUserHasOfferer,
				dealershipsId,
			});
		},
		setNewBidSalesArray: (state, { payload }) => {
			const { bidSalesArr } = payload;

			const bidSaleById = state.bidSalesThatUserHasOfferer.reduce((acc, bidSale) => {
				acc[bidSale.bid_sale_id] = bidSale;
				return acc;
			}, {});

			// discard incoming changes if they are older than the stored state
			const updatedBidSalesArr = bidSalesArr.map((bidSale) => {
				const storedBidSale = bidSaleById[bidSale.bid_sale_id];
				if (storedBidSale && isOlderVersion({ incomingChanges: bidSale, storedState: storedBidSale })) {
					return storedBidSale;
				}
				return bidSale;
			});

			state.stats = calculateSummaryFromList({
				bidSalesThatUserHasOfferer: updatedBidSalesArr,
				dealershipsId: state.dealershipsId,
			});
			state.bidSalesThatUserHasOfferer = updatedBidSalesArr;
			state.loading = false;
			state.success = true;
		},
		offerSummaryFetchInit: (state, { payload }) => {
			state.dealershipsId = payload.dealershipsId;
			state.loading = true;
			state.success = null;
		},
		offerSummaryFetchError: (state) => {
			state.loading = false;
			state.success = false;
		},
	},
});

export const { updateOfferSummary, setNewBidSalesArray, offerSummaryFetchInit, offerSummaryFetchError } =
	buyerOfferSummarySlice.actions;

export default buyerOfferSummarySlice.reducer;
