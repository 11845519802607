import { notifyError } from 'helpers/errorNotifier';

export const getPathFromURL = (url, defaultOnError) => {
	const defaultValue = defaultOnError || '/';
	try {
		const urlObj = new URL(url, process.env.BASE_URL);
		return `${urlObj.pathname}${urlObj.search}` || defaultValue;
	} catch (e) {
		return defaultValue;
	}
};

export const createAbsoluteUrlFromPath = (urlPath) => {
	let baseUrl = process.env.BASE_URL;
	if (typeof window !== 'undefined' && window?.location?.origin) {
		baseUrl = window.location.origin;
	}
	return `${baseUrl}${urlPath}`;
};

export const couldBePath = (path) => path.startsWith('/');

export const ensurePathToBeValid = (path) => (couldBePath(path) ? path : `/${path}`);

export const getUtmParamsString = (params) => {
	return getAllowedParamsString(params, ['utm_']);
};
export const getUtmAndAFCParamsString = (params) => {
	return getAllowedParamsString(params, ['utm_', 'afc']);
};

export const createUrlFromParams = (params, filterEntries) => {
	if (!params) return '';
	try {
		const entries = filterEntries ? Object.entries(params).filter(filterEntries) : Object.entries(params);
		return entries.map((pair) => pair.map(encodeURIComponent).join('=')).join('&');
	} catch (error) {
		notifyError(`Error trying to exectue createUrlFromParams: ${error}`, {
			metadata: {
				query: params,
			},
		});
		return '';
	}
};

export const getAllowedParamsString = (params, allowedPrefixesArr = []) => {
	return createUrlFromParams(params, ([key]) =>
		allowedPrefixesArr.some((allowedPrefix) => key.startsWith(allowedPrefix)),
	);
};
