import { BID_SALE_SOURCE_TAB } from 'constants/bidSale';
import { MARKETPLACE_TAB } from 'constants/marketplace';
import { addToWatchlist, removeFromWatchlist } from 'store/slices/buyerVehicles/buyerVehicles';
import {
	loadVehicles as loadAuctionVehicles,
	loadVehiclesMarketplace,
	unHideVehicle,
} from 'store/slices/buyerVehicles/buyerVehiclesThunks';

const reloadWatchlistPage = ({ page, isAuction, loggedUser, closedTradeNetwork, unifiedMarketplace }) => {
	if (isAuction && !unifiedMarketplace) {
		return loadAuctionVehicles({
			page,
			loggedUser,
			sourceTab: BID_SALE_SOURCE_TAB.watchlist,
			searchListKey: BID_SALE_SOURCE_TAB.watchlist,
		});
	} else {
		return loadVehiclesMarketplace({
			loggedUser,
			closedTradeNetwork,
			searchListKey: MARKETPLACE_TAB.watchlist,
			page,
			unifiedMarketplace,
		});
	}
};

export const addVehicleToWatchlist =
	({ vehicleToWatch, loggedUser, unifiedMarketplace, closedTradeNetwork, isAuction }) =>
	async (dispatch, getState) => {
		const {
			buyerVehicles: { entities },
		} = getState();

		const listKey = isAuction && !unifiedMarketplace ? BID_SALE_SOURCE_TAB.watchlist : MARKETPLACE_TAB.watchlist;
		const watchlistSearchList = getState().buyerVehicles.searchLists[listKey];
		const { vehicleIds } = watchlistSearchList;
		const currentPage = watchlistSearchList.currentSearch.params.page;
		const firstVehicleBidSaleId = entities[vehicleIds[0]]?.bid_sale_id;

		// we should unhide vehicle if it was previously hidden
		if (vehicleToWatch.hidden) {
			dispatch(
				unHideVehicle({
					vehicleToUnHide: vehicleToWatch,
					loggedUser,
					searchListKey: listKey,
					unifiedMarketplace,
				}),
			);
		}

		// get page when the element was added into previous page, so current page was shifted
		if (vehicleIds.length && vehicleToWatch.bid_sale_id > firstVehicleBidSaleId && currentPage !== 1) {
			dispatch(
				reloadWatchlistPage({
					page: currentPage,
					isAuction,
					loggedUser,
					closedTradeNetwork,
					unifiedMarketplace,
				}),
			);
		} else {
			dispatch(addToWatchlist({ vehicle: vehicleToWatch, listKey }));
		}
	};

export const removeVehicleFromWatchlist =
	({ vehicle, loggedUser, isAuction, closedTradeNetwork, unifiedMarketplace }) =>
	async (dispatch, getState) => {
		const {
			buyerVehicles: { entities },
		} = getState();

		const listKey = isAuction && !unifiedMarketplace ? BID_SALE_SOURCE_TAB.watchlist : MARKETPLACE_TAB.watchlist;
		const watchlistList = getState().buyerVehicles.searchLists[listKey];
		const currentPage = watchlistList.currentSearch.params.page;
		const { pageCount, vehicleIds } = watchlistList;

		let doNotModifyCurrentPage = false;

		// get page if the current page was "shifted" by deletion on current or previous pages and there are more pages so we have to refresh
		if (
			vehicleIds.length &&
			vehicle.bid_sale_id > entities[vehicleIds[vehicleIds.length - 1]].bid_sale_id &&
			currentPage < pageCount
		) {
			dispatch(
				reloadWatchlistPage({
					page: currentPage,
					isAuction,
					loggedUser,
					closedTradeNetwork,
					unifiedMarketplace,
				}),
			);
			doNotModifyCurrentPage = true;
		} else if (
			currentPage > 1 &&
			currentPage === pageCount &&
			vehicleIds.length === 1 &&
			entities[vehicleIds[0]].bid_sale_id === vehicle.bid_sale_id
		) {
			dispatch(
				reloadWatchlistPage({
					page: currentPage - 1,
					isAuction,
					loggedUser,
					closedTradeNetwork,
					unifiedMarketplace,
				}),
			);
			doNotModifyCurrentPage = true;
		}
		dispatch(removeFromWatchlist({ vehicle, doNotModifyCurrentPage, watchlistListKey: listKey }));
	};
